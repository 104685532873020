footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 30px;
  background-color: #228b22;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;

  p {
    padding: 0;
    margin: 0;
    font-size: 0.8rem;
    color: #fff;
  }
}
