.home-container {
  background-image: url(../Components/Assets/images/home_banner.png);
  background-size: cover;
  height: 90vh;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .heading-container {
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
  }

  h1 {
    font-size: 3rem;
    padding: 2% 1%;
    color: #fff;

    @media only screen and (max-width: 800px) {
      font-size: 2.5rem;
    }

    @media only screen and (max-width: 500px) {
      font-size: 2rem;
    }
  }
}

.button-container {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 3% 0;
  width: 100%;

  a {
    color: #fff;
    text-decoration: none;
    padding: 0;
    margin: 0 3%;

    button {
      align-items: center;
      padding: 1.2rem;
      font-size: 1.5rem;
      border: 1px solid #98473e;
      border-radius: 10px;
      width: 100%;
      margin: 0 5%;
      background-color: #98473e;
      color: #fff;
      cursor: pointer;

      &:hover {
        background-color: #88958d;
        color: #fff;
        border: 1px solid #88958d;
        box-shadow: 1px 3px 10px #152614;
      }
    }
  }

  @media only screen and (max-width: 950px) {
    padding-bottom: 5%;
    display: flex;
    flex-direction: column;

    a {
      button {
        margin: 0;
        margin-bottom: 10px;
      }
    }
  }
}
