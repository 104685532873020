.login-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 60%;
    height: 300px;

    input {
      margin: 10px 0;
      border-radius: 5px;
    }

    p {
      color: red;
      font-size: 0.7rem;
    }

    button {
      width: 30%;
      padding: 1.5%;
      margin: 3% 0;
      cursor: pointer;
      background-color: #98473e;
      color: #fff;
      border: 1px solid #98473e;

      &:hover {
        background-color: #88958d;
        color: #fff;
        border: 1px solid #88958d;
        box-shadow: 1px 3px 10px #152614;
      }
    }
  }
}
