@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffebd0; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.App {
  text-align: center; }

body {
  position: relative;
  min-height: 100%;
  min-height: 100vh; }

body {
  font-family: 'Open Sans', sans-serif; }

.navigation-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  height: 60px;
  background-color: #228b22; }
  @media only screen and (max-width: 800px) {
    .navigation-links h1 {
      display: none; } }
  .navigation-links .links-container {
    display: flex;
    width: 80%;
    justify-content: flex-end; }
    @media only screen and (max-width: 800px) {
      .navigation-links .links-container {
        width: 100%;
        justify-content: space-evenly; } }
    .navigation-links .links-container a {
      padding: 20px;
      text-decoration: none;
      color: #fff; }
      .navigation-links .links-container a:hover {
        color: #ff7b31; }
      @media only screen and (max-width: 650px) {
        .navigation-links .links-container a {
          padding: 10px;
          font-size: 0.8rem; } }

.profile-header {
  display: flex;
  margin: 0 auto;
  width: 90%;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 55px;
  color: #98473e; }
  .profile-header .guide-info-half button {
    border-radius: 15px;
    padding: 5%;
    margin: 2%;
    background-color: #98473e;
    color: #fff;
    border: 1px solid #98473e;
    cursor: pointer; }
    .profile-header .guide-info-half button:hover {
      background-color: #88958d;
      color: #fff;
      border: 1px solid #88958d;
      box-shadow: 1px 3px 10px #152614; }
  @media only screen and (max-width: 600px) {
    .profile-header .guide-info-half {
      font-size: 1rem;
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center; }
      .profile-header .guide-info-half .button-profile-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        align-content: center;
        text-align: center; }
        .profile-header .guide-info-half .button-profile-container a {
          margin: 0;
          padding: 0; }
        .profile-header .guide-info-half .button-profile-container a,
        .profile-header .guide-info-half .button-profile-container button {
          width: 100%;
          cursor: pointer;
          padding: 3% 0;
          border-radius: 15px; } }
  .profile-header .profile-name {
    font-size: 3rem;
    margin-bottom: 10px; }
  .profile-header .profile-title {
    margin: 10px 0;
    font-size: 2rem; }
  .profile-header .profile-specialty {
    margin: 10px 0;
    font-size: 1.5rem; }
  .profile-header p {
    font-size: 1.3rem; }
  .profile-header .tagline {
    font-style: italic; }
  .profile-header .profile-pic-half {
    padding: 3% 0;
    width: 50%; }
    .profile-header .profile-pic-half img {
      max-width: 100%;
      max-height: 40vh;
      border-radius: 40px; }
  @media only screen and (max-width: 600px) {
    .profile-header {
      flex-direction: column; } }

.trips-section-title {
  font-size: 2rem;
  color: #98473e; }

.trips-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-bottom: 5%;
  margin: 0 auto; }
  @media only screen and (max-width: 600px) {
    .trips-container {
      padding-bottom: 10%; } }

.trip-card {
  background: #1b2f33;
  padding: 13px 15px;
  border-radius: 10px;
  margin: 15px 25px;
  width: 450px; }
  .trip-card .trip-card-button-container {
    display: flex;
    width: 80%;
    align-items: center;
    justify-content: center; }
    @media only screen and (max-width: 600px) {
      .trip-card .trip-card-button-container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        justify-items: center;
        width: 100%; } }
    .trip-card .trip-card-button-container a {
      width: 100%; }
    .trip-card .trip-card-button-container button {
      width: 30%;
      padding: 1.5%;
      margin: 3% 1%;
      border-radius: 15px;
      cursor: pointer;
      background-color: #98473e;
      color: #fff;
      border: 1px solid #98473e; }
      .trip-card .trip-card-button-container button:hover {
        background-color: #88958d;
        color: #fff;
        border: 1px solid #88958d;
        box-shadow: 1px 3px 10px #152614; }
      @media only screen and (max-width: 600px) {
        .trip-card .trip-card-button-container button {
          width: 100%; } }
  @media only screen and (max-width: 600px) {
    .trip-card {
      width: 350px; } }
  @media only screen and (max-width: 500px) {
    .trip-card {
      width: 200px; } }
  .trip-card:hover {
    box-shadow: 1px 3px 10px #152614; }

a {
  color: #f7f0f0;
  text-decoration: none; }

@media only screen and (max-width: 950px) {
  .search-form {
    padding-bottom: 10%; } }

@media only screen and (max-width: 600px) {
  .search-form {
    padding-bottom: 15%; } }

input {
  width: 60%;
  padding: 0.6% 1%;
  font-size: 1.2rem; }

.login-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }
  .login-container .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    height: 300px; }
    .login-container .login-form input {
      margin: 10px 0;
      border-radius: 5px; }
    .login-container .login-form p {
      color: red;
      font-size: 0.7rem; }
    .login-container .login-form button {
      width: 30%;
      padding: 1.5%;
      margin: 3% 0;
      cursor: pointer;
      background-color: #98473e;
      color: #fff;
      border: 1px solid #98473e; }
      .login-container .login-form button:hover {
        background-color: #88958d;
        color: #fff;
        border: 1px solid #88958d;
        box-shadow: 1px 3px 10px #152614; }

.signup-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }
  .signup-container .signup-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    height: 300px; }
    .signup-container .signup-form input {
      margin: 10px 0;
      border-radius: 5px; }
    .signup-container .signup-form p {
      color: red;
      font-size: 0.7rem; }
    .signup-container .signup-form button {
      width: 30%;
      padding: 1.5%;
      margin: 3% 0;
      cursor: pointer;
      background-color: #98473e;
      color: #fff;
      border: 1px solid #98473e; }
      .signup-container .signup-form button:hover {
        background-color: #88958d;
        color: #fff;
        border: 1px solid #88958d;
        box-shadow: 1px 3px 10px #152614; }

.home-container {
  background-image: url(/static/media/home_banner.a2b58524.png);
  background-size: cover;
  height: 90vh;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; }
  .home-container .heading-container {
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%; }
  .home-container h1 {
    font-size: 3rem;
    padding: 2% 1%;
    color: #fff; }
    @media only screen and (max-width: 800px) {
      .home-container h1 {
        font-size: 2.5rem; } }
    @media only screen and (max-width: 500px) {
      .home-container h1 {
        font-size: 2rem; } }

.button-container {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 3% 0;
  width: 100%; }
  .button-container a {
    color: #fff;
    text-decoration: none;
    padding: 0;
    margin: 0 3%; }
    .button-container a button {
      align-items: center;
      padding: 1.2rem;
      font-size: 1.5rem;
      border: 1px solid #98473e;
      border-radius: 10px;
      width: 100%;
      margin: 0 5%;
      background-color: #98473e;
      color: #fff;
      cursor: pointer; }
      .button-container a button:hover {
        background-color: #88958d;
        color: #fff;
        border: 1px solid #88958d;
        box-shadow: 1px 3px 10px #152614; }
  @media only screen and (max-width: 950px) {
    .button-container {
      padding-bottom: 5%;
      display: flex;
      flex-direction: column; }
      .button-container a button {
        margin: 0;
        margin-bottom: 10px; } }

footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 30px;
  background-color: #228b22;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center; }
  footer p {
    padding: 0;
    margin: 0;
    font-size: 0.8rem;
    color: #fff; }

.newtrip-form-container {
  display: flex;
  flex-direction: column;
  width: 60%;
  align-content: center;
  margin: 0 auto;
  align-items: center;
  margin-bottom: 20%;
  padding-top: 1.5%;
  font-size: 1.2rem; }
  .newtrip-form-container textarea {
    min-width: 80%;
    max-width: 80%;
    max-height: 25vh; }
  .newtrip-form-container input,
  .newtrip-form-container textarea {
    width: 80%;
    padding: 1%;
    margin: 1% 0; }
    @media only screen and (max-width: 550px) {
      .newtrip-form-container input,
      .newtrip-form-container textarea {
        font-size: 1rem;
        padding: 2%;
        margin: 2.5% 0; } }
  .newtrip-form-container button {
    width: 30%;
    padding: 1.5%;
    margin: 3% 0;
    cursor: pointer;
    background-color: #98473e;
    color: #fff;
    border: 1px solid #98473e; }
    .newtrip-form-container button:hover {
      background-color: #88958d;
      color: #fff;
      border: 1px solid #88958d;
      box-shadow: 1px 3px 10px #152614; }
  .newtrip-form-container p {
    color: red;
    font-size: 0.7rem;
    padding: 0%;
    margin: 0%; }
  @media only screen and (max-width: 550px) {
    .newtrip-form-container {
      width: 90%;
      font-size: 1rem; } }

.thankyou-container a {
  color: #98473e; }
  .thankyou-container a:hover {
    text-decoration: underline; }

.browse-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .browse-container .profile-card {
    color: #98473e;
    width: 250px;
    height: 250px;
    padding: 20px;
    margin: 40px;
    border: 1px solid; }

