.App {
  text-align: center;
}

body {
  position: relative;
  min-height: 100%;
  min-height: 100vh;
}

@import "./sass/global.scss";
@import "./sass//NavigationLinks.scss";
@import "./sass//profile.scss";
@import "./sass//login.scss";
@import "./sass//signup.scss";
@import "./sass/Home.scss";
@import "./sass/Footer.scss";
@import "./sass//NewTrip.scss";
@import "./sass/ThankYou.scss";
@import './sass/ProfileCards.scss';
