.profile-header {
  display: flex;
  margin: 0 auto;
  width: 90%;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 55px;
  color: #98473e;

  .guide-info-half {
    button {
      border-radius: 15px;
      padding: 5%;
      margin: 2%;
      background-color: #98473e;
      color: #fff;
      border: 1px solid #98473e;
      cursor: pointer;

      &:hover {
        background-color: #88958d;
        color: #fff;
        border: 1px solid #88958d;
        box-shadow: 1px 3px 10px #152614;
      }
    }

    @media only screen and (max-width: 600px) {
      font-size: 1rem;
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;

      .button-profile-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        align-content: center;
        text-align: center;

        a {
          margin: 0;
          padding: 0;
        }

        a,
        button {
          width: 100%;
          cursor: pointer;
          padding: 3% 0;
          border-radius: 15px;
        }
      }
    }
  }

  .profile-name {
    font-size: 3rem;
    margin-bottom: 10px;
  }
  .profile-title {
    margin: 10px 0;
    font-size: 2rem;
  }
  .profile-specialty {
    margin: 10px 0;
    font-size: 1.5rem;
  }
  p {
    font-size: 1.3rem;
  }

  .tagline {
    font-style: italic;
  }

  .profile-pic-half {
    padding: 3% 0;
    width: 50%;
    img {
      max-width: 100%;
      max-height: 40vh;
      border-radius: 40px;
    }
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.trips-section-title {
  font-size: 2rem;
  color: #98473e;
}

.trips-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-bottom: 5%;
  margin: 0 auto;

  @media only screen and (max-width: 600px) {
    padding-bottom: 10%;
  }
}

.trip-card {
  background: #1b2f33;
  padding: 13px 15px;
  border-radius: 10px;
  margin: 15px 25px;
  width: 450px;

  .trip-card-button-container {
    display: flex;
    width: 80%;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      justify-items: center;
      width: 100%;
    }

    a {
      width: 100%;
    }

    button {
      width: 30%;
      padding: 1.5%;
      margin: 3% 1%;
      border-radius: 15px;
      cursor: pointer;
      background-color: #98473e;
      color: #fff;
      border: 1px solid #98473e;

      &:hover {
        background-color: #88958d;
        color: #fff;
        border: 1px solid #88958d;
        box-shadow: 1px 3px 10px #152614;
      }

      @media only screen and (max-width: 600px) {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    width: 350px;
  }

  @media only screen and (max-width: 500px) {
    width: 200px;
  }

  &:hover {
    box-shadow: 1px 3px 10px #152614;
  }
}

a {
  color: #f7f0f0;
  text-decoration: none;
}

.search-form {
  @media only screen and (max-width: 950px) {
    padding-bottom: 10%;
  }

  @media only screen and (max-width: 600px) {
    padding-bottom: 15%;
  }
}

input {
  width: 60%;
  padding: 0.6% 1%;
  font-size: 1.2rem;
}
