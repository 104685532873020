.navigation-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  height: 60px;
  background-color: #228b22;

  h1 {
    @media only screen and (max-width: 800px) {
      display: none;
    }
  }

  .links-container {
    display: flex;
    width: 80%;
    justify-content: flex-end;

    @media only screen and (max-width: 800px) {
      width: 100%;
      justify-content: space-evenly;
    }

    a {
      padding: 20px;
      text-decoration: none;
      color: #fff;

      &:hover {
        color: #ff7b31;
      }

      @media only screen and (max-width: 650px) {
        padding: 10px;
        font-size: 0.8rem;
      }
    }
  }
}
